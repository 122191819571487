import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { IconArrowDown16 } from 'components/base/Icon';
import { useToggle } from 'hooks';
import { WEB_MAX_WIDTH, gray050, gray300, gray500, gray700, gray800 } from 'styles';
import { typography } from 'styles/font';

const BrowserHref = {
  business_info: 'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2148891525',
  service_terms: 'https://cf.res.s.zigzag.kr/terms/user/service.html',
  privacy: 'https://cf.res.s.zigzag.kr/terms/user/privacy.html',
};

export default function Footer() {
  const [is_fold, toggleFold] = useToggle(true);

  const is_show_new_address = dayjs().isAfter('2024-02-04', 'day');

  const old_adress = '서울특별시 강남구 테헤란로 521, 27층 (삼성동, 파르나스타워)';
  const new_adress = '(13494) 경기도 성남시 분당구 판교역로 235 에이치스퀘어 N동 6층';

  return (
    <StyledFooter>
      <Wrapper>
        <HeaderWrapper>
          <Header>(주) 카카오스타일</Header>
          <Business onClick={toggleFold}>
            사업자 정보
            {is_fold ? <IconArrowDown16 /> : <IconArrowDown16 className='up' />}
          </Business>
        </HeaderWrapper>
        {!is_fold && (
          <>
            <Paragraph>대표 : 서정훈</Paragraph>
            <Paragraph>주소 : {is_show_new_address ? new_adress : old_adress}</Paragraph>
            <Paragraph>사업자등록번호 : 214-88-91525</Paragraph>
            <Paragraph>통신판매업 신고 : 서울-강남-03345</Paragraph>
            <Paragraph>호스팅서비스사업자 : (주)카카오스타일</Paragraph>
            <Paragraph>이메일 : style@kakaostyle.com</Paragraph>
            <Paragraph>고객센터 : 1670-8160</Paragraph>
            <Paragraph>전화상담 : 평일 (09:00 ~ 18:00)</Paragraph>
          </>
        )}
        <Paragraph
          css={css`
            margin: 16px 0;
          `}
        >
          패션바이카카오는 상품에 직접 관여하지 않으며 상품 주문, 배송 및 환불의 의무와 책임은 각 판매 업체에 있습니다.
        </Paragraph>
        <AnchorWrapper>
          <Anchor href={BrowserHref.business_info} target='_blank'>
            사업자 정보 조회
          </Anchor>
          <Dot />
          <Anchor href={BrowserHref.service_terms} target='_blank'>
            이용약관
          </Anchor>
          <Dot />
          <Anchor is_bold href={BrowserHref.privacy} target='_blank'>
            개인정보처리방침
          </Anchor>
        </AnchorWrapper>
      </Wrapper>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  background: ${gray050};
`;

const Wrapper = styled.div`
  max-width: ${WEB_MAX_WIDTH}px;
  padding: 24px 16px 118px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 118px);
  padding-bottom: calc(constant(safe-area-inset-bottom) + 118px);
  margin: auto;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
`;
const Header = styled.h1`
  ${typography.body4_bold}
  color: ${gray700};
`;

const Business = styled.button`
  ${typography.small2}
  color: ${gray500};
  display: flex;
  align-items: flex-end;

  .up {
    transform: rotate(180deg);
  }
  > svg {
    margin-left: 4px;
  }
`;

const Paragraph = styled.p`
  ${typography.small1_regular}
  color: ${gray500};
  margin: 0 0 5px;
  letter-spacing: -0.3px;
`;

const AnchorWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${gray700};
`;

const Anchor = styled.a<{ is_bold?: boolean }>`
  ${({ is_bold }) => (is_bold ? typography.small1_bold : typography.small2)}
  color: ${({ is_bold }) => (is_bold ? gray800 : gray700)};
`;

const Dot = styled.div`
  background: ${gray300};
  width: 3px;
  height: 3px;
  margin: 0 7px;
`;
